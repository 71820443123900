export class FilterForm {
    constructor() {
        this.form = $("#filter-form");
        this.formElement = document.querySelector("#filter-form");
        this.language = $("#language-filter");
        this.certificate = $("#certificate-filter");
        this.level = $("#level");
        this.exam = $("#exam");
        this.province = $(`#${FilterForm.provinceId}`);
        this.initializeOnlineOption();
        this.sticky();
        this.initializeSummary();
    }
    get computerBased() {
        return $("#computer-based").val();
    }
    initializeOnlineOption() {
        this.onlineOption = document
            .getElementById(FilterForm.provinceId)
            ?.querySelector("option[value='_online']");
        if (this.onlineOption !== null && this.onlineOption !== undefined) {
            this.onlineOptionPreviousSibling = this.onlineOption.previousSibling;
            this.reinitializeOnParentChanged();
        }
    }
    sticky() {
        const stickableElement = document.querySelector(".js-stickable");
        if (stickableElement === null) {
            return;
        }
        const offsetBuscador = stickableElement.getBoundingClientRect().top +
            (window.scrollY || document.documentElement.scrollTop);
        window.addEventListener("scroll", (e) => {
            if (document.documentElement.scrollTop >
                offsetBuscador + stickableElement.offsetHeight) {
                stickableElement.classList.add("sticky2");
            }
            else {
                stickableElement.classList.remove("sticky2");
            }
        });
    }
    reinitializeOnParentChanged() {
        this.language.on("change", () => {
            this.certificate.trigger("select2:re-initialize");
            this.exam.trigger("select2:re-initialize");
            this.province.trigger("change");
        });
        this.certificate.on("change", () => {
            this.exam.trigger("select2:re-initialize");
            const hasOnline = this.certificate.select2("data")[0].hasOnline ?? true;
            if (hasOnline && this.onlineOption.parentElement === null) {
                this.onlineOptionPreviousSibling.after(this.onlineOption);
            }
            else if (!hasOnline && this.onlineOption.parentElement !== null) {
                this.onlineOption.parentElement.removeChild(this.onlineOption);
            }
            this.province.trigger("change");
        });
        this.level.on("change", () => {
            this.exam.trigger("select2:re-initialize");
        });
    }
    get formExists() {
        return this.form.length > 0;
    }
    initializeSummary() {
        const parent = this.formElement?.parentElement;
        if (parent === null || parent === undefined) {
            return;
        }
        const summary = parent.querySelector(".js-search-summary");
        if (summary === null || summary === undefined) {
            return;
        }
        summary.addEventListener("click", () => {
            parent.classList.add("form-open");
        });
        parent
            .querySelector(".js-search-backdrop")
            .addEventListener("click", () => {
            parent.classList.remove("form-open");
        });
        parent
            .querySelector(".js-close-search-form")
            .addEventListener("click", () => {
            parent.classList.remove("form-open");
        });
    }
}
FilterForm.provinceId = "province";
